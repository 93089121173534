import emailjs from '@emailjs/browser';
import swal from 'sweetalert';
import * as Constants from "../../helper/Constants";

export const sendEmail = (e, form, showResult, setFormData) => {
    e.preventDefault();

    emailjs
        .sendForm(
            Constants.SERVICE_ID,
            Constants.TEMPLATE_ID,
            form.current,
            Constants.PUBLIC_API_KEY
        )
        .then(
            (result) => {
                swal({
                    title: "Thank you for contacting MDCHCPL.",
                    text: " We will get in touch shortly.",
                    icon: "success",
                });
                e.target.reset();
                setFormData({
                    name: '',
                    email: '',
                    mobile: '',
                    institution: '',
                    subject: '',
                    message: '',
                })
            },
            (error) => {
                swal({
                    title: "Something went wrong",
                    text: "Wires are broken in our end, please try again later.",
                    icon: "error",
                });
            });
    showResult(true);
};

export const formValidation = (e, formData) => {
    e.preventDefault();

    const { name, email, mobile, institution, subject, message } = formData;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phoneRegex = /^(\+?[1-9]\d*)?(\d*)([1-9]+\d{9})$/;

    if (name.trim() === '' || name.trim().length < 4) {
        return { isValid: false, errorMessage: 'Name should be atleast 4 characters long' };
    }

    if (email.trim() === '' || !emailRegex.test(email.trim())) {
        return { isValid: false, errorMessage: 'Invalid Email Address' }
    }

    if (mobile.trim() === '' || !phoneRegex.test(mobile.trim()) || mobile.length < 10) {
        return { isValid: false, errorMessage: 'Invalid Mobile Number' }
    }

    if (institution.trim() === '') {
        return { isValid: false, errorMessage: 'Institution should not be empty' }
    }

    if (subject.trim() === '') {
        return { isValid: false, errorMessage: 'Subject should not be empty' }
    }

    if (message.trim() === '') {
        return { isValid: false, errorMessage: 'Interested in field should not be empty' }
    }

    return { isValid: true, errorMessage: '' };
}
