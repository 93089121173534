import React from "react";
import about from "../../images/about.svg";

const About = () => {
  return (
    <>
      <section id="about" className="d-flex align-items-center fadeAnim pb-5">
        <div className="container-fluid nav_bg py-5">
          <div className="row">
            <div className="col-10 mx-auto">
              <div className="row">
                <div className="col-lg-6 order-1 order-lg-1 about-img py-4">
                  <img
                    src={about}
                    className="img-fluid animated"
                    alt="MDCHCPL"
                    width="400"
                  />
                </div>
                <div className="col-lg-6 col-md-12 pt-5 pt-lg-5 order-2 order-lg-1 d-flex justify-content-center flex-column">
                  <h2 className="brand-name">About Us.</h2>
                  <p className="my-3 jus">
                    We, MDC Healthcare Private Limited (MDCHCPL) is an ISO Certified
                    professionally managed organisation backed with 38 years of
                    Medical &amp; Laboratory Diagnostic Industry Experience run
                    by a Qualified Team. Our activities include sales,
                    marketing, service and technical support to various
                    Diagnostic Testing Laboratory, Hospitals, Clinical &amp;
                    Life Science Research Institutions prevailing in Government,
                    PSU, Corporate, Non-Profit Organisation and Private sectors
                    for over 25.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
