import React from 'react'

const Carousel = (props) => {
  let { items } = props
  return (
    <div className="row mb-5">
      <div className="col-lg-10 mx-auto">
        <div className="row gy-4">
          <div className="slider">
            <div className="slide-track-partners">
              {items.map((item, index) => (
                <div className="slide-partners" key={index}>
                  {item.url !== '' ? (<a
                    target="_blank"
                    href={item.url}
                    rel="noopener noreferrer"
                  >
                    <img src={item.imgSrc} alt={item.name} />
                  </a>) : (
                    <a>
                      <img src={item.imgSrc} alt={item.name} />
                    </a>
                  )
                  }
                </div>
              ))}
              {/* Repeated partners for continuous loop effect */}
              {items.map((item, index) => (
                <div className="slide-partners" key={`repeat-${index}`}>


                  {item.url !== '' ? (<a
                    target="_blank"
                    href={item.url}
                    rel="noopener noreferrer"
                  >
                    <img src={item.imgSrc} alt={item.name} />
                  </a>) : (
                    <a>
                      <img src={item.imgSrc} alt={item.name} />
                    </a>
                  )
                  }
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Carousel