import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";
import * as Constants from "../../helper/Constants";
import { sendEmail, formValidation } from './ContactUtils.js';
import { MapLocation } from './MapLocation.jsx'

const Contact = () => {
  const form = useRef();
  const [result, showResult] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    institution: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const result = formValidation(e, formData)
    if (result.isValid) {
      sendEmail(e, form, showResult, setFormData);
    } else {
      window.alert(result.errorMessage);
    }
  };


  return (
    <section id="contact" className="fadeAnim">
      <div className="d-flex align-items-center p-5">
        <div className="container-fluid ">
          <div className="row justify-content-center">
            <div className="col-12 shadow-sm col-md-10 col-lg-9">
              <div className="card border-0">
                <div className="row no-gutters">
                  <div className="col-12 col-lg-5 p-4 d-flex flex-column justify-content-center bg-light">
                    <div className="contact-leftside">
                      <h1 className="my-4">Contact Us</h1>
                      <div className="mb-3">
                        <h5>Mail</h5>
                        <p>
                          {Constants.EMAIL_PRIMARY}
                          <br />
                          {Constants.EMAIL_SECONDARY}
                        </p>
                      </div>
                      <div className="mb-3">
                        <h5>Mobile</h5>
                        <p>
                          +91 {Constants.MOBILE_NUM_PRIMARY}
                          <br />
                          +91 {Constants.MOBILE_NUM_SECONDARY}
                          <br />
                          +91 {Constants.MOBILE_NUM_TERTIARY}
                        </p>
                      </div>
                      <div>
                        <h5>Location</h5>
                        <p>
                          No.4 Papathi Ammal Street, Jain Colony
                          <br />
                          Kodambakkam, Chennai-600024,
                          <br />
                          Tamil Nadu, India
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-7 p-4 d-flex flex-column justify-content-center align-items-center">
                    <form ref={form} onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12 mb-3">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <select
                                className="form-select"
                                name="title"
                                id="title"
                                required
                              >
                                <option
                                  value=""
                                  className="text-muted"
                                  disabled
                                  selected
                                >
                                  Title
                                </option>
                                <option value="Dr">Dr.</option>
                                <option value="Ms">Ms.</option>
                                <option value="Mrs">Mrs.</option>
                                <option value="Mr">Mr.</option>
                              </select>
                            </div>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              className="form-control"
                              placeholder="Name"
                              value={formData.name}
                              onChange={handleChange}
                              required={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-lg-6 mb-3">
                          <input
                            type="text"
                            name="email"
                            id="email"
                            className="form-control"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                          <input
                            type="text"
                            name="mobile"
                            id="mobile"
                            className="form-control"
                            placeholder="Mobile Number"
                            value={formData.mobile}
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mb-3">
                          <input
                            type="text"
                            name="institution"
                            id="institution"
                            className="form-control"
                            placeholder="Institution"
                            value={formData.institution}
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mb-3">
                          <input
                            type="text"
                            name="subject"
                            id="subject"
                            className="form-control"
                            placeholder="Subject"
                            value={formData.subject}
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mb-3">
                          <input
                            type="text"
                            name="message"
                            id="message"
                            className="form-control"
                            placeholder="Interested in"
                            value={formData.message}
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <input
                            type="submit"
                            className="btn btn-primary form-control"
                            value="Submit"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className=' p-0 d-flex flex-column justify-content-center bg-light'>
                    <MapLocation />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Contact;
