import React from "react";
import web from "../../images/home.svg";
import { NavLink } from "react-router-dom";

const Home = () => {
    return (
        <>
            <section id="header" className="d-flex align-items-center fadeAnim">
                <div className="container-fluid nav_bg">
                    <div className="row">
                        <div className="col-10 mx-auto">
                            <div className="row">
                                <div className="col-md-6 pt-5 pt-lg-4 order-2 order-lg-1 d-flex justify-content-center flex-column">
                                    <h2 className="brand-name">
                                        MDC Healthcare Private Limited
                                    </h2>
                                    <h4 className="my-3 text-red">
                                        License to serve.
                                    </h4>
                                    <div className="mt-3">
                                        <NavLink to="/about" className="btn btn-primary">Explore Now</NavLink>
                                    </div>
                                </div>
                                <div className="col-lg-6 order-1 order-lg-2 header-img">
                                    <img src={web} className="img-fluid animated" alt="MDCHCPL" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};

export default Home;