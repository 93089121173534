import React from "react";
import biorad from "../../logos/biorad.svg";
import molbio from "../../logos/molbio.svg";
import qiagen from "../../logos/qiagen.svg";
import mpbio from "../../logos/mpbio.svg";
import accurex from "../../logos/accurex.svg";
import transasia from "../../logos/transasia.svg";
import agappe from "../../logos/agappe.svg";
import mylab from "../../logos/mylab.svg";
import diaclone from "../../logos/diaclone.svg";
import stago from "../../logos/stago.svg";
import il from "../../logos/il.svg";
import alifax from "../../logos/alifax.svg";
import rapid from "../../logos/rapid.svg";
import dbo from "../../logos/dbo.svg";
import suyog from "../../logos/suyog.svg";
import aarthi from "../../images/customers/aarthi.png";
import anderson from "../../images/customers/anderson.png";
import apollo from "../../images/customers/apollo.png";
import ckc from "../../images/customers/ckc.png";
import cmc from "../../images/customers/cmc.png";
import jipmer from "../../images/customers/jipmer.png";
import kauvery from "../../images/customers/kauvery.png";
import medall from "../../images/customers/medall.png";
import metropolis from "../../images/customers/metropolis.png";
import naruvi from "../../images/customers/naruvi.png";
import neuberg from "../../images/customers/neuberg.png";
import primegen from "../../images/customers/primegen.png";
import sims from "../../images/customers/sims.png";
import smf from "../../images/customers/smf.png";
import southernrailway from "../../images/customers/southernrailway.png";
import srmglobal from "../../images/customers/srmglobal.png";
import star from "../../images/customers/star.png";
import vrr from "../../images/customers/vrr.png";

import "./Partners.css";
import Carousel from "../../components/Carousel";

const Partners = () => {
  const partners = [
    { name: "Bio-Rad", url: "https://www.bio-rad.com/", imgSrc: biorad },
    {
      name: "Molbio Diagnostics",
      url: "https://www.molbiodiagnostics.com/",
      imgSrc: molbio,
    },
    { name: "Qiagen", url: "https://www.qiagen.com/us/", imgSrc: qiagen },
    { name: "MPBio", url: "https://www.mpbio.com/us", imgSrc: mpbio },
    { name: "Accurex", url: "https://accurex.net/", imgSrc: accurex },
    { name: "Transasia", url: "https://transasia.co.in/", imgSrc: transasia },
    { name: "Agappe", url: "https://www.agappe.com/in/", imgSrc: agappe },
    {
      name: "Mylab Discovery Solutions",
      url: "https://mylabdiscoverysolutions.com/",
      imgSrc: mylab,
    },
    { name: "Stago", url: "https://www.stago.com/", imgSrc: stago },
    { name: "Werfen", url: "https://www.werfen.com/na/en", imgSrc: il },
    { name: "Alifax", url: "https://www.alifax.com/", imgSrc: alifax },
    { name: "Diaclone", url: "https://www.diaclone.com/", imgSrc: diaclone },
    {
      name: "Rapid Diagnostics",
      url: "http://www.rapiddiagnostic.in/",
      imgSrc: rapid,
    },
    { name: "DBO", url: "https://dbopas.com/", imgSrc: dbo },
    {
      name: "Suyog Diagnostics",
      url: "https://www.suyogdiagnostics.com/",
      imgSrc: suyog,
    },
  ];
  
  const customers = [
    { name: "Aarthi", url: "", imgSrc: aarthi },
    { name: "Anderson", url: "", imgSrc: anderson },
    { name: "Apollo", url: "", imgSrc: apollo },
    { name: "CKC", url: "", imgSrc: ckc },
    { name: "CMC", url: "", imgSrc: cmc },
    { name: "JIPMER", url: "", imgSrc: jipmer },
    { name: "Kauvery", url: "", imgSrc: kauvery },
    { name: "Medall", url: "", imgSrc: medall },
    { name: "Metropolis", url: "", imgSrc: metropolis },
    { name: "Naruvi", url: "", imgSrc: naruvi },
    { name: "Neuberg", url: "", imgSrc: neuberg },
    { name: "Primegen", url: "", imgSrc: primegen },
    { name: "SIMS", url: "", imgSrc: sims },
    { name: "SMF", url: "", imgSrc: smf },
    { name: "Southern Railway", url: "", imgSrc: southernrailway },
    { name: "SRM Global", url: "", imgSrc: srmglobal },
    { name: "Star", url: "", imgSrc: star },
    { name: "VRR", url: "", imgSrc: vrr }
];

  return (
    <section id="partners" className="d-flex align-items-center fadeAnim" >
    <div className="container-fluid mb-5">
      <div className="row text-center mb-4">
        <h3 className='my-6'>Our Partners</h3>
      </div>
      <Carousel items={partners} />
      <div className="row text-center mb-4">
        <h3 className='my-6'>Our Customers</h3>
      </div>
      <Carousel items={customers} />
    </div>
  </section>
  
    
  );
};

export default Partners;
