export const SERVICE_ID = 'service_gdciyxr'
export const TEMPLATE_ID = 'template_yqgex2r'
export const PUBLIC_API_KEY = 'n4T9gnEbmeRtV4BBv'

export const MOBILE_NUM_PRIMARY = '9710264300'
export const MOBILE_NUM_SECONDARY = '9841031104'
export const MOBILE_NUM_TERTIARY = '9841031105'

export const EMAIL_PRIMARY = 'mdcchennai@gmail.com'
export const EMAIL_SECONDARY = 'mdc_support@mdchealthcare.com'

export const MAP_URL = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.8356720157176!2d80.21684917554721!3d13.046129787276124!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526797ddb48c15%3A0x5a3cd147fca8e40a!2sMDC%20Healthcare%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1722756387038!5m2!1sen!2sin'
