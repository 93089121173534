import React from 'react'
import * as Constants from "../../helper/Constants";

export const MapLocation = () => {
    return (
        <iframe
            src={Constants.MAP_URL}
            height="125"
        >
        </iframe>
    )
}

export default MapLocation