import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import '../node_modules/font-awesome/css/font-awesome.min.css';


import Home from './pages/home/Home';
import About from './pages/about/About';
// import Products from './pages/services/Products';
import Contact from './pages/contact/Contact';
import Partners from './pages/partners/Partners';

// import Equipment from './Equipment';
// import Appsupport from './Appsupport';
// import Servsupport from './Servsupport';
// import Reagents from './pages/services/Reagents';

import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import { Switch, Route, Redirect } from "react-router-dom";


const App = () => {
  return (
    <>
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/partners" component={Partners} />
        {/* <Route exact path="/products" component={Products} /> */}
        <Route exact path="/contact" component={Contact} />
{/* 
        <Route exact path="/equipment" component={Equipment} />
        <Route exact path="/appsupport" component={Appsupport} />
        <Route exact path="/servsupport" component={Servsupport} />
        <Route exact path="/reagents" component={Reagents} /> */}
        <Redirect to="/" />
      </Switch>
      <Footer />
    </>
  );
};

export default App;