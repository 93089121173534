import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="fadeAnim footer fixed-bottom mt-auto">
        <div class="container">
          <p>
            © MDC Healthcare Private Limited | 2024
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
